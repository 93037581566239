import { ILanguageData } from "../props";

export const esES: ILanguageData = {
  welcome_login: "Bienvenido a iniciar sesión",
  account: "Numero de Cuenta",
  password: "Contraseña",
  remember_pwd: "Recordar la contraseña",
  login: "Iniciar sesión",
  login_account_required: "Se requiere nombre de usuario",
  login_pwd_required: "Se requiere contraseña",
  input_keyword_search: "Por favor, introduzca la búsqueda de palabras clave",
  learning_data: "Aprende los datos",
  my_course: "Mi curso",
  my_exam: "Mi examen",
  more_courses: "Más cursos",
  exam_review: "Evaluación del examen",
  departmental_course: "Tutorial departamental",
  quick_generate_tutorial: "Generación rápida del tutorial",
  update_tutorial: "Actualización del tutorial",
  all: "Total",
  pending_participation_exam: "Pendiente",
  pending_review_exam: "Pendiente de revisión",
  not_passed_exam: "No aprobado",
  passed_exam: "Ha pasado",
  course_number: "Número del curso",
  course_title: "Nombre del curso",
  exam_time: "Tiempo de examen",
  exam_duration: "Duración del examen",
  quiz_creator: "Persona que elabora las preguntas",
  total_question_number: "Número total de preguntas",
  exam_number: "Número de examen",
  remaining_exam_attempts: "Número de exámenes restantes",
  times: "veces",
  check_score: "Ver resultados",
  start_exam: "Comenzar examen",
  compulsory_course: "Obligatorio",
  elective_course: "Optativo",
  duration: "Duración",
  course: "Curso",
  pcs: "Piezas",
  minutes: "Minutos",
  data_learned: "Ha estudiado",
  to_learn: "A estudiar",
  learned: "Ha estudiado",
  learning: "En el estudio",
  history: "Registro histórico",
  learning_dueation: "Tiempo de estudio",
  lecturer: "Instructor",
  exam_start_time: "Hora de inicio del examen",
  exam_end_time: "Hora de finalización del examen",
  // score: "Puntuación: {{score}} Puntos",
  score_one: "Puntuación: {{score}} Punto",
  score_other: "Puntuación: {{score}} Puntos",
  view_details: "Ver detalles",
  copy_link: "Copiar enlace",
  failed_grade: "Reprobado",
  passed_grade: "Aprobado",
  single_choice: "Elecciones individuales",
  multiple_choice: "Selección múltiple",
  judgment: "Juicio",
  fill_blank: "Rellenar los espacios en blanco",
  // total_score: "Puntuación total: {{totalScore}} Puntos",
  total_score_one: "Puntuación total: {{totalScore}} Punto",
  total_score_other: "Puntuación total: {{totalScore}} Puntos",
  // questions_total: "Total de {{questionCount}} preguntas",
  questions_total_other: "Total de {{questionCount}} preguntas",
  questions_total_one: "Total de {{questionCount}} pregunta",
  exam_submission_time: "Tiempo de envío del examen",
  exam_paper_number: "Número de examen",
  number_of_associated_course: "Número de curso asociado",
  exam_marker: "Revisor",
  number_of_questions_to_be_reviewed: "Número de preguntas por revisar",
  review_status: "Estado de la evaluación",
  operation: "Operación",
  to_be_reviewed: "Pendiente de revisión",
  reviewed: "Revisado", // 未确定
  distributed: "Distribuido", // 未确定
  review: "Revisión",
  department: "Departamento",
  position: "Puestos",
  nameOfViewer: "Nombre del espectador",
  organizationOfTheViewer: "Organización a la que pertenece el espectador",
  viewingStatus: " Estado de visualización",
  viewingTime: "Tiempo de visualización",
  question_and_answer: "Preguntas y respuestas",
  i_posted: "Lo publiqué.",
  name: "Nombre",
  description: "Descripción",
  personnel_scope: "Alcance del personal",
  shelf_time: "Tiempo en los estantes",
  courseware_upload: "Material didáctico",
  click_or_drag: "Haga clic o arrastre archivos aquí para subir",
  extension_support: "Extensiones soportadas",
  cancel: "Cancelar",
  confirm: "Confirmar",
  organizational_structure: "Estructura organizativa",
  selected_person: "Personal seleccionado",
  message_center: "Centro de mensajes",
  message_list: "Lista de mensajes",
  unread_message: "Mensajes no leídos",
  read_message: "Mensajes leídos",
  on_the_shelf: "En proceso de publicación",
  removed_from_shelves: "Fuera de línea",
  unwatched: "Sin ver",
  watched: "Visto",
  viewing_record: "Ver registro",
  my_viewing_record: "Mi historial de visualizaciones",
  delete: "Eliminar",
  modify: "Modificar",
  publisher: "Editor",
  viewing_time: "Tiempo de visualización",
  name_of_viewer: "Nombre del espectador",
  organization_of_the_viewer: "Organización a la que pertenece el espectador",
  viewing_status: "Estado de visualización",
  tutorial_staff_watches_the_record: "Los tutores ven los registros",
  course_introduction: "Introducción al curso",
  more: "Más",
  course_evaluation: "Evaluación curricular",
  correctAnswerMessage: "¡Felicidades, respuesta correcta!",
  wrongAnswer: "Lamentablemente, respuesta incorrecta.",
  yourAnswer: "Tu respuesta: {{yourAnswer}}",
  correctAnswer: "Respuesta correcta: {{correctAnswer}}",
  answerAnalysis: "Análisis de respuestas: {{answerAnalysis}}",
  comment: "Comentario:{{comment}}",
  mark: "Calificación: {{score}} Puntos",
  remainingTime: "Tiempo restante: ",
  submit: "Enviar",
  submit_failed: "Falló el envío, ¡intente de nuevo!",
  submit_success: "¡Envío exitoso!",
  viewing_history: "Ver registros históricos",
  courseware_name: "Nombre del material didáctico",
  courseware_number: "Número del material didáctico",
  dept_course: "Tutorial departamental",
  dept_tutorials_number: "Número del tutorial departamental",
  my_dept_tutorials: "Mis tutoriales del departamento estudiados",
  course_description: "Descripción del curso",
  tutorial_publish: "Publicación del tutorial",
  tutorial_description: "Introducción al tutorial",
  copy: "Copiar",
  copy_success: "Copiado con éxito",
  copy_failed: "Fallo al copiar",
  i_learned: "Mis estudios",
  multiplier: "Doble velocidad",
  clarity: "Claridad",
  whether_to_delete_data: "¿Eliminar datos?",
  yes: "Sí",
  no: "No",
  file_type_not_supported: "Solo se admiten formatos MP4, MOV, JPG, PNG, PDF",
  student_count: "Número total de estudiantes",
  course_instructor: "Instructor del curso",
  learning_progress: "Progreso del aprendizaje",
  my_pending_courses: "Mis cursos pendientes",
  dept_course_number: "Número de curso del departamento",
  join_course: "¿Participar en el curso?",
  course_expired:
    "El curso ha excedido el tiempo de estudio disponible y no puede continuar.",
  no_tutorials: "No hay tutoriales disponibles",
  upload_type_tips:
    "Solo se pueden subir archivos del mismo tipo, y solo se puede subir uno de MP4 y PDF",
  examination_title: "Nombre del examen",
  paper_number: "Número de examen",
  submitter: "Persona que envía",
  submission_time: "Tiempo de envío",
  distribution_success: "Puntuaciones distribuidas con éxito",
  distribute: "Distribuir", // 不確定
  attachment: "Adjunto",
  confirm_exit_exam: "¿Salir del examen?",
  clear_unread: "Marcar todo como leído",
  scan_qr_code:
    "Por favor, escanee el código QR arriba para llenar la evaluación del curso",
  distribution_select_results:
    "Por favor, seleccione las puntuaciones a distribuir",
  pending_examination: "Exámenes pendientes de aprobación",
  test_paper_submission_time: "Tiempo de envío del examen",
  answer: "Respuesta",
  review_score: "Calificación",
  points: "Puntos",
  review_remark: "Comentario",
  review_remark_empty: "Los comentarios no pueden estar vacíos",
  review_points_empty: "La puntuación no puede estar vacía",
  tutorials: "Tutorial",
  tutorials_number: "Número del tutorial",
  logout: "Salir",
  version: "Versión",
  empty_exams: "No hay exámenes todavía",
  empty_course: "No hay cursos todavía",
  empty_score: "No hay resultados todavía",
  empty_content: "No hay contenido aún",
  clear: "Vacíe",
  search_history: "Historial de búsqueda",
};
