/* eslint-disable unicorn/filename-case */
/* eslint-disable camelcase */

import { ILanguageData } from "../props";

export const zhTW: ILanguageData = {
  welcome_login: "歡迎登錄",
  account: "賬號",
  password: "密碼",
  remember_pwd: "記住密碼",
  login: "登錄",
  login_account_required: "必須輸入賬號",
  login_pwd_required: "必須輸入密碼",
  input_keyword_search: "請輸入關鍵字搜索",
  learning_data: "學習數據",
  my_course: "我的課程",
  my_exam: "我的考試",
  more_courses: "更多課程",
  exam_review: "考試評審",
  departmental_course: "部門教程",
  quick_generate_tutorial: "快速生成教程",
  update_tutorial: "更新教程",
  all: "全部",
  pending_participation_exam: "待參加",
  pending_review_exam: "待評審",
  not_passed_exam: "未通過",
  passed_exam: "已通過",
  course_number: "課程編號",
  course_title: "課程名稱",
  exam_time: "考試時間",
  exam_duration: "考試時長",
  quiz_creator: "出題人",
  total_question_number: "總題數",
  exam_number: "考試編號",
  remaining_exam_attempts: "剩餘考試次數",
  times: "次",
  check_score: "查看成績",
  start_exam: "開始考試",
  compulsory_course: "必修",
  elective_course: "選修",
  duration: "時長",
  course: "課程",
  pcs: "個",
  minutes: "分鐘",
  data_learned: "已學習", // 不確定
  to_learn: "待學習",
  learned: "已學習",
  learning: "學習中",
  history: "歷史記錄",
  learning_dueation: "學習時間",
  lecturer: "講師",
  exam_start_time: "考試開始時間",
  exam_end_time: "考試結束時間",
  // score: "得分：{{score}}分",
  score_one: "得分：{{score}}分",
  score_other: "得分：{{score}}分",
  view_details: "查看詳情",
  copy_link: "複製鏈接",
  failed_grade: "不及格",
  passed_grade: "及格",
  single_choice: "單選",
  multiple_choice: "多選",
  judgment: "判斷",
  fill_blank: "填空",
  // total_score: "總分：{{totalScore}}分",
  total_score_one: "總分：{{totalScore}}分",
  total_score_other: "總分：{{totalScore}}分",
  // questions_total: "共{{questionCount}}題",
  questions_total_other: "共{{questionCount}}題",
  questions_total_one: "共{{questionCount}}題",
  exam_submission_time: "考卷提交時間",
  exam_paper_number: "考卷編號",
  number_of_associated_course: "關聯課程編號",
  exam_marker: "評卷人",
  number_of_questions_to_be_reviewed: "待評審題數",
  review_status: "評審狀態",
  operation: "操作",
  to_be_reviewed: "待評審",
  reviewed: "已評審", // 未確定
  distributed: "已派發", // 未确定
  review: "評審",
  department: "部門",
  position: "職位",
  nameOfViewer: "觀看人姓名",
  organizationOfTheViewer: "觀看人所屬組織",
  viewingStatus: "觀看狀態",
  viewingTime: "觀看時間",
  question_and_answer: "問答",
  i_posted: "我發佈的",
  name: "名稱",
  description: "描述",
  personnel_scope: "人員範圍",
  shelf_time: "上架時間",
  courseware_upload: "課件",
  click_or_drag: "點擊或將文件拖拽到這裏上傳",
  extension_support: "支持擴展名",
  cancel: "取消",
  confirm: "確認",
  organizational_structure: "組織架構",
  selected_person: "已選人員",
  message_center: "消息中心",
  message_list: "消息列表",
  unread_message: "未讀消息",
  read_message: "已讀消息",
  on_the_shelf: "上架中",
  removed_from_shelves: "已下架",
  unwatched: "未觀看",
  watched: "已觀看",
  viewing_record: "觀看記錄",
  my_viewing_record: "我的觀看記錄",
  delete: "刪除",
  modify: "修改",
  publisher: "發佈者",
  viewing_time: "觀看時間",
  name_of_viewer: "觀看人姓名",
  organization_of_the_viewer: "觀看人所屬組織",
  viewing_status: "觀看狀態",
  tutorial_staff_watches_the_record: "教程人員觀看記錄",
  course_introduction: "課程簡介",
  more: "更多",
  course_evaluation: "課程評價",
  correctAnswerMessage: "恭喜你，回答正確！",
  wrongAnswer: "很遺憾，回答錯誤！",
  yourAnswer: "您的答案：{{yourAnswer}}",
  correctAnswer: "標準答案：{{correctAnswer}}",
  answerAnalysis: "答案解析：{{answerAnalysis}}",
  comment: "評語：{{comment}}",
  mark: "評分：{{score}}分",
  remainingTime: "剩餘時間：",
  submit: "提交",
  submit_failed: "提交失敗，請重試！",
  submit_success: "提交成功！",
  viewing_history: "觀看歷史記錄",
  courseware_name: "課件名稱",
  courseware_number: "課件編號",
  dept_course: "部門教程",
  dept_tutorials_number: "部門教程編號",
  my_dept_tutorials: "我學習的部門教程",
  course_description: "課程描述",
  tutorial_publish: "教程發布",
  tutorial_description: "教程簡介",
  copy: "複製",
  copy_success: "複製成功",
  copy_failed: "複製失敗",
  i_learned: "我學習的",
  multiplier: "倍速",
  clarity: "清晰度",
  whether_to_delete_data: "是否刪除數據",
  yes: "是",
  no: "否",
  file_type_not_supported: "僅支持MP4、MOV、JPG、PNG、PDF格式",
  student_count: "學員總數",
  course_instructor: "課程講師",
  learning_progress: "學習進度",
  my_pending_courses: "我的待學習課程",
  dept_course_number: "部門課程編號",
  join_course: "是否參與課程",
  course_expired: "課程已超出可學習時間，無法進行學習",
  no_tutorials: "暫無教程",
  upload_type_tips: "只能上傳同一類附件，且MP4和PDF只能上傳一份",
  examination_title: "考試名稱", // 不確定
  paper_number: "考卷編號", // 不確定
  submitter: "提交人", // 不確定
  submission_time: "提交時間", // 不確定
  distribution_success: "成績派發成功", // 不確定
  distribute: "派發成績", // 不確定
  attachment: "附件",
  confirm_exit_exam: "是否退出考試？",
  clear_unread: "一鍵已讀",
  scan_qr_code: "請掃描以上二維碼填寫課程評價",
  distribution_select_results: "請選擇要派發的成績",
  pending_examination: "待審批考試", // 不确定
  test_paper_submission_time: "考卷提交時間", // 不確定
  answer: "答案", // 不確定
  review_score: "評分", // 不確定
  points: "分", // 不確定
  review_remark: "評語", // 不確定
  review_remark_empty: "評語不能為空", // 不确定
  review_points_empty: "評分不能為空", // 不确定
  tutorials: "教程",
  tutorials_number: "教程編號",
  logout: "退出",
  version: "版本號",
  empty_exams: "暫無考試",
  empty_course: "暫無課程",
  empty_score: "暫無成績",
  empty_content: "暫無内容",
  clear: "清空",
  search_history: "搜索歷史",
};
