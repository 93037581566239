/* eslint-disable unicorn/filename-case */
/* eslint-disable camelcase */

import { ILanguageData } from "../props";

export const enUS: ILanguageData = {
  welcome_login: "Welcome to login",
  account: "Account number",
  password: "password",
  remember_pwd: "Remember password",
  login: "login",
  login_account_required: "username is required",
  login_pwd_required: "password is required",
  input_keyword_search: "Please enter keyword search",
  learning_data: "Learning data",
  my_course: "My courses",
  my_exam: "My exams",
  more_courses: "More courses",
  exam_review: "Exam review",
  departmental_course: "Departmental course",
  quick_generate_tutorial: "Quick generation tutorial",
  update_tutorial: "Update tutorial",
  all: "All",
  pending_participation_exam: "To be attended",
  pending_review_exam: "To be reviewed",
  not_passed_exam: "Not passed",
  passed_exam: "Passed",
  course_number: "Course Number",
  course_title: "Course title",
  exam_time: "Exam time",
  exam_duration: "Exam duration",
  quiz_creator: "Quiz Creator",
  total_question_number: "Total number of questions",
  exam_number: "Exam Number",
  remaining_exam_attempts: "Number of remaining exams",
  times: "times",
  check_score: "Check the score",
  start_exam: "Start exam",
  compulsory_course: "Mandatory course",
  elective_course: "Elective course",
  duration: "Duration",
  course: "Course",
  pcs: "PCS",
  minutes: "minutes",
  data_learned: "Learned", // 不確定
  to_learn: "To learn",
  learned: "Learned",
  learning: "Learning",
  history: "History",
  learning_dueation: "Learning Time",
  lecturer: "Lecturer",
  exam_start_time: "Exam start time",
  exam_end_time: "Exam end time",
  // score: "score： {{score}}points",
  score_one: "score：{{score}}point",
  score_other: "score：{{score}}points",
  view_details: "View details",
  copy_link: "Copy links",
  failed_grade: "failed",
  passed_grade: "passed",
  single_choice: "Single choice",
  multiple_choice: "Multiple choice",
  judgment: "True or false",
  fill_blank: "Fill in the blank",
  // total_score: "Total score：{{totalScore}}points",
  total_score_one: "Total score：{{totalScore}}point",
  total_score_other: "Total score：{{totalScore}}points",
  // questions_total: "{{questionCount}}questions in total",
  questions_total_other: "{{questionCount}} questions in total",
  questions_total_one: "{{questionCount}} question in total",
  exam_submission_time: "Exam Submission Time",
  exam_paper_number: "Exam paper number",
  number_of_associated_course: "Number of associated course",
  exam_marker: "Exam marker",
  number_of_questions_to_be_reviewed: "Number of questions to be reviewed",
  review_status: "Review status",
  operation: "operation",
  to_be_reviewed: "To be reviewed",
  reviewed: "Reviewed", // 未确定
  distributed: "Distributed", // 未确定
  review: "review",
  department: "Department",
  position: "Position",
  nameOfViewer: "Name of viewer",
  organizationOfTheViewer: "Organization of the viewer",
  viewingStatus: "Viewing status",
  viewingTime: "Viewing time",
  question_and_answer: "Question and answer",
  i_posted: "I posted",
  name: "name",
  description: "description",
  personnel_scope: "Scope of personnel",
  shelf_time: "Available from",
  courseware_upload: "Courseware",
  click_or_drag: "Click or drag the file here to upload",
  extension_support: "Extension support",
  cancel: "Cancel",
  confirm: "Confirm",
  organizational_structure: "Organizational structure",
  selected_person: "Selected person(s)",
  message_center: "Message center",
  message_list: "Message list",
  unread_message: "Unread Message",
  read_message: "Read Message",
  on_the_shelf: "On the shelf",
  removed_from_shelves: "Removed from shelves",
  unwatched: "Unwatched",
  watched: "Watched",
  viewing_record: "Viewing record",
  my_viewing_record: "My viewing record",
  delete: "Delete",
  modify: "Modify",
  publisher: "Publisher",
  viewing_time: "Viewing time",
  name_of_viewer: "Name of viewer",
  organization_of_the_viewer: "Organization of the viewer",
  viewing_status: "Viewing status",
  tutorial_staff_watches_the_record: "The tutorial staff watches the record",
  course_introduction: "Course Introduction",
  more: "More",
  course_evaluation: "Course evaluation",
  correctAnswerMessage: "Congratulations, you answered correctly！",
  wrongAnswer: "Sorry, your answer is incorrect!",
  yourAnswer: "Your answer：{{yourAnswer}}",
  correctAnswer: "Correct answer：{{correctAnswer}}",
  answerAnalysis: "Answer Analysis：{{answerAnalysis}}",
  comment: "Comment：{{comment}}",
  mark: "Score: {{score}} points",
  remainingTime: "Remaining Time：",
  submit: "Submit",
  submit_failed: "Submission failed, please try again！",
  submit_success: "Submission succeeded！",
  viewing_history: "Viewing history",
  courseware_name: "Courseware name",
  courseware_number: "Courseware number",
  dept_course: "Departmental course",
  dept_tutorials_number: "Departmental Course Number",
  my_dept_tutorials: "My Department Tutorials",
  course_description: "Course Description",
  tutorial_publish: "Tutorial Publish",
  tutorial_description: "Tutorial Description",
  copy: "Copy",
  copy_success: "Copy successful",
  copy_failed: "Copy failed",
  i_learned: "I learned",
  multiplier: "multiplier",
  clarity: "clarity",
  whether_to_delete_data: "Whether to delete data",
  yes: "Yes",
  no: "No",
  file_type_not_supported: "Only MP4, MOV, JPG, PNG, PDF formats are supported",
  student_count: "Student Count",
  course_instructor: "Course Instructor",
  learning_progress: "Learning Progress",
  my_pending_courses: "My Pending Courses",
  dept_course_number: "Department Course Number",
  join_course: "Join Course",
  course_expired:
    "Course has exceeded the learning time and cannot be accessed",
  no_tutorials: "No tutorials",
  upload_type_tips:
    "Only the same type of attachments can be uploaded, and only one copy of MP4 and PDF files can be uploaded",
  examination_title: "Examination title", // 不確定
  paper_number: "Paper number", // 不確定
  submitter: "Submitter", // 不确定
  submission_time: "Submission time", // 不確定
  distribution_success: "Distribution success", // 不確定
  distribute: "Distribute", // 不確定
  attachment: "Attachment",
  confirm_exit_exam: "Do you want to exit the exam?",
  clear_unread: "Clear Unread Message",
  scan_qr_code:
    "Please scan the QR code above to fill out the course evaluation",
  distribution_select_results: "Please select a result to distribute", // 不确定
  pending_examination: "Pending examination", // 不确定
  test_paper_submission_time: "Test paper submission time", // 不確定
  answer: "answer", // 不確定
  review_score: "score", // 不確定
  points: "points", // 不確定
  review_remark: "comment", // 不確定
  review_remark_empty: "Comments cannot be empty", // 不确定
  review_points_empty: "Points cannot be empty", // 不确定
  tutorials: "Tutorials",
  tutorials_number: "Tutorials Number",
  logout: "Log out",
  version: "Version",
  empty_exams: "No examination",
  empty_course: "No course",
  empty_score: "No results",
  empty_content: "No content",
  clear: "Clear",
  search_history: "Search history",
};
